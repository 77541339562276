import type { StaticImageData } from 'next/image';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from '../../styles/Download.module.css';
import IconDownload from '../icons/IconDownload';

interface Props {
  img: StaticImageData;
  title: string;
  cta: string;
  url: string;
}

export default function Download({
  img, title, cta, url
}: Props) {
  return (
    <div className={styles.wrap}>
      <Link href={url} className={styles.image} target="_blank">
        <Image
          src={img}
          alt={title}
          layout="fill"
          objectFit="contain"
          objectPosition="right"
        />
      </Link>

      <div className={styles.content}>
        <div className={styles.title}>{title}</div>

        <Link href={url} className={styles.cta} target="_blank">
          <IconDownload height={12} width={12} fill="#82a50f" />
          <span>{cta}</span>
        </Link>
      </div>
    </div>
  );
}
