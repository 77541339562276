import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconDownload(props: React.SVGProps<Props>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 73.808 73.808"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M64.582 50.743v13.839H9.226V50.743H0v13.839a9.253 9.253 0 0 0 9.226 9.226h55.356a9.253 9.253 0 0 0 9.226-9.226V50.743Zm-4.613-18.452-6.5-6.5-11.948 11.9V0h-9.23v37.688l-11.948-11.9-6.5 6.5L36.9 55.356Z"
      />
    </svg>
  );
}
